<template>
  <v-row>
    <v-col class="mt-6 pb-0" cols="12">
      <v-card>
        <v-card-actions>
          <v-icon @click="close">mdi-arrow-left-circle</v-icon>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" class="pt-0">
      <v-card>
        <v-card-title class="h4">Export Data</v-card-title>
        <v-card-text>
           <v-progress-circular
      :size="100"
      :width="15"
      :rotate="360"
      :value="value"
      color="teal"
    >
      {{ value }}
    </v-progress-circular>
          <v-col class="pr-4" cols="12" md="6">
            <v-btn color="primary" type="button" @click="exportParticipants">Participants</v-btn>
            &nbsp;
            <v-btn color="primary" type="button" @click="exportUsers" v-if="isSystemAdmin">Users</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as caseService from "../../services/caseService";
import * as userService from "../../services/userService";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import { firestore } from 'firebase';


Vue.use(VeeValidate);

export default {
  data: () => ({
    vm: {},
    value:0,
    isSystemAdmin:false
  }),
  mounted() {
    this.util = this.$utils;
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    init() {
      if(this.user.roles.indexOf("System Administrator") > -1){
        this.isSystemAdmin =true;
      }
    },
    exportParticipants() {
      var data = [];
      let me = this;
      caseService.list(this.currentOrganisation.id).then(querySnapshot => {
       let i=0
       me.value=0;
        querySnapshot.docs.map(doc => {
          i++;
          const x = doc.data();
          x.id = doc.id;
           me.value = Math.ceil(((i)/querySnapshot.size)*100);
          if(x.participantDetails!==undefined){
            data.push({
              Id: x.id,
              FirstName: me.$utils.emptyThen(x.participantDetails.firstname, ""),
              LastName: me.$utils.emptyThen(x.participantDetails.lastname, ""),
              CRNNumber: me.$utils.emptyThen(x.participantDetails.cRNNumber, ""),
              NDISNumber: me.$utils.emptyThen(
                x.participantDetails.nDISNumber,
                ""
              ),
              MedicareNumber: me.$utils.emptyThen(
                x.participantDetails.medicareNumber,
                ""
              ),
              SWSNumber: me.$utils.emptyThen(x.participantDetails.sWSNumber, ""),
              TaxFileNumber: me.$utils.emptyThen(
                x.participantDetails.taxFileNumber,
                ""
              ),
              Address: me.$utils.toAddress(x.participantDetails.address),
              IsPostalTheSame: me.$utils.emptyThen(
                x.participantDetails.isPostalTheSame,
                ""
              ),
              PostalAddress: me.$utils.toAddress(
                x.participantDetails.postalAddress
              ),

              DateOfBirth: me.$utils.isEmpty(x.participantDetails.dateOfBirth)
                ? ""
                : me.$utils.toDt(x.participantDetails.dateOfBirth).toISOString(),

              Gender: me.$utils.emptyThen(x.participantDetails.gender, ""),
              Email: me.$utils.emptyThen(x.participantDetails.email, ""),
              HomeNumber: me.$utils.emptyThen(
                x.participantDetails.homeNumber,
                ""
              ),
              MobileNumber: me.$utils.emptyThen(
                x.participantDetails.mobileNumber,
                ""
              ),

              MaritalStatus: me.$utils.emptyThen(
                x.participantDetails.maritalStatus,
                ""
              ),

              PrimaryDisability: me.$utils.emptyThen(
                x.participantDetails.primaryDisability,
                ""
              ),
              SecondaryDisability: me.$utils.emptyThen(
                x.participantDetails.secondaryDisability,
                ""
              ),

              Status: me.$utils.emptyThen(x.status, ""),
              PlanStart: (me.$utils.isEmpty(x.planDetails) || me.$utils.isEmpty(x.planDetails.planStart))
                ? ""
                : me.$utils.toDt(x.planDetails.planStart).toISOString(),
              PlanEnd: (me.$utils.isEmpty(x.planDetails) ||me.$utils.isEmpty(x.planDetails.planEnd))
                ? ""
                : me.$utils.toDt(x.planDetails.planEnd).toISOString(),
              field1: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field1, ""),
              field2: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field2, ""),
              field3: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field3, ""),
              field4: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field4, ""),
              field5: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field5, ""),
              field6: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field6, ""),
              field7: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field7, ""),
              field8: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field8, ""),
              field9: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field9, ""),
              field10: me.$utils.isEmpty(x.additionalData)
                ? ""
                : me.$utils.emptyThen(x.additionalData.field10, "")
            });
          }
        });

        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: false,
          //title: "",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
        setTimeout(function(){
            me.value=0
        }, 2000);
      });
    },


    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },
    exportUsers() {
      var data = [];
      let me = this;
      userService.list(this.currentOrganisation.id).then(querySnapshot => {
        let i=0;
        me.value=0;
        querySnapshot.docs.map(doc => {
          i++;
          const x = doc.data();
          x.id = doc.id;
          me.value = Math.ceil(((i)/querySnapshot.size)*100);
          data.push({
            id: x.id,
            organisationId:x.organisationId,
            status: me.$utils.emptyThen(x.status, ""),
            displayName: me.$utils.emptyThen(x.displayName, ""),
            email: me.$utils.emptyThen(x.email, ""),
            createdAt: me.toDt(x.createdAt),
            firstName: me.$utils.emptyThen(x.firstName,""),
            lastName: me.$utils.emptyThen(x.lastName,""),
            isEnabled: me.$utils.emptyThen(x.isEnabled,""),
            lastLoginAt: me.toDt(x.lastLoginAt),
            roles: me.$utils.emptyThen(x.roles,""),
          });
        });

        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: false,
          //title: "",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);

        setTimeout(function(){
            me.value=0
        }, 2000);
      });
    },
    toDt(value) {
      if (value !== undefined && value !== null) {
        if (typeof value.toDate !== "undefined") {
          return value.toDate();
        } else if (typeof value == "string") {
          return firestore.Timestamp.fromMillis(value).toDate();
        } else {
          return value;
        }
      }
    },
    close() {
      this.$router.push("/admin");
    }
  }
};
</script>
